import galleryImage01 from '../../assets/images/gallery-01.jpg'
import galleryImage02 from '../../assets/images/gallery-02.jpg'
import galleryImage03 from '../../assets/images/gallery-03.jpg'
import galleryImage04 from '../../assets/images/gallery-04.jpg'
import galleryImage05 from '../../assets/images/gallery-05.jpg'
import galleryImage06 from '../../assets/images/gallery-06.jpg'
import galleryImage07 from '../../assets/images/gallery-07.jpg'

const galleryImages = [
    galleryImage01,
    galleryImage02,
    galleryImage03,
    galleryImage04,
    galleryImage05,
    galleryImage06,
    galleryImage07
]

export default galleryImages