import React from "react";
import "../styles/home.css";
import { Container, Row, Col } from "reactstrap";
import Subtitle from "../shared/Subtitle";
import worldImg from "../assets/images/world.png";
import heroImg01 from "../assets/images/hero-img01.jpg";
import heroImg02 from "../assets/images/hero-img02.jpg";
import heroVideo from "../assets/images/hero-video.mp4";
import experienceImg from "../assets/images/experience.png";
import SearchBar from "../shared/SearchBar";
import ServiceList from "../services/ServiceList";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../shared/Newsletter";

const Home = () => {
  return (
    <>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-items-center">
                  <Subtitle subtitle={"Your journey, your story!"} />
                  <img src={worldImg} alt="" />
                </div>

                <h1>
                  Make every trip a <span className="highlight">story</span>{" "}
                  worth sharing
                </h1>
                <p>
                  We connect you with top-rated tour operators to ensure an
                  unforgettable travel experience. Whether you're exploring
                  local gems or embarking on a new adventure, you can browse and
                  book from a wide range of trusted providers. Your journey
                  begins here, with comfort and quality guaranteed!
                </p>
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box">
                <img src={heroImg01} alt="" />
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box mt-4">
                <video src={heroVideo} controls />
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box mt-5">
                <img src={heroImg02} alt="" />
              </div>
            </Col>

            <SearchBar />
          </Row>
        </Container>
      </section>

      {/* ========== hero section start =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="3">
              <Subtitle subtitle={"What we serve"} />
              <h2 className="services__title">We offer the best services</h2>
            </Col>

            <ServiceList />
          </Row>
        </Container>
      </section>

      {/* ========= featured tour section start ========= */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Our featured tours</h2>
            </Col>
            <FeaturedTourList />
          </Row>
        </Container>
      </section>
      {/* ========= featured tour section end ========= */}

      {/* ========== experience section start =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="experience__content">
                <Subtitle subtitle={"Experience"} />

                <h2>
                  With years of expertise <br /> we will serve you
                </h2>

                <p>
                  With our extensive experience, we're dedicated to providing
                  you with exceptional service and unforgettable experiences
                </p>
              </div>

              <div className="counter__wrapper d-flex align-items-center gap-5">
                <div className="counter__box">
                  <span>12k+</span>
                  <h6>Successful trips</h6>
                </div>
                <div className="counter__box">
                  <span>2k+</span>
                  <h6>Regular clients</h6>
                </div>
                <div className="counter__box">
                  <span>15</span>
                  <h6>Years experience</h6>
                </div>
              </div>
            </Col>

            <Col lg="6">
              <div className="experience__img">
                <img src={experienceImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* ========== experience section end =========== */}

      {/* ========== gallery section start ========== */}
      <section>
        <Container>
          <Col lg="12">
            <Subtitle subtitle={"Galllery"} />
            <h2 className="gallery_title">Visit our tour gallery</h2>
          </Col>

          <Col lg="12">
            <MasonryImagesGallery />
          </Col>
        </Container>
      </section>
      {/* ========== gallery section end ========== */}

      {/* ========== testimonial section start ========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Fans Love"} />
              <h2 className="testimonial__title">What our fans say about us</h2>
            </Col>

            <Col lg="12">
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>
      {/* ========== testimonial section end ========== */}

      <Newsletter />
    </>
  );
};

export default Home;
