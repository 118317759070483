// src/slices/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token') || '', // Initialize token from local storage
  login: JSON.parse(localStorage.getItem('login')) || {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload); // Save token to local storage
    },
    clearToken: (state) => {
      state.token = ''; // Set token to empty string
      localStorage.removeItem('token'); // Remove token from local storage
    },
    setLogin: (state, action) => {
      state.login = action.payload;
      localStorage.setItem('login', JSON.stringify(action.payload)); // Save login data to local storage
    },
  },
});

export const { setToken, clearToken, setLogin } = authSlice.actions;
export default authSlice.reducer;
