import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { backendurl } from '../../../config/backend';
import { useSelector } from 'react-redux';
import { Card, Button } from 'reactstrap';
import Edit_Tour from './Edit_Tour';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Get_Tour = () => {
  const navigate = useNavigate();
  const [tours, setTours] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const user = useSelector((state) => state.auth.login);

  useEffect(() => {
    fetchToursAndSchedules();
  }, []);

  const fetchToursAndSchedules = async () => {
    try {
      const tourResponse = await axios.get(`${backendurl}/api/tour/get`);
      setTours(tourResponse.data);  // Tours now include admin, company, and category details
    } catch (err) {
      toast.error('Error fetching tours:', err);
      setError(`Error fetching tours: ${err.message}`);
    }
    try {
      const tourScheduleResponse = await axios.get(`${backendurl}/api/tour/schedule/get`);
      setSchedules(tourScheduleResponse.data);
    } catch (err) {
      console.error('Error fetching tour schedules:', err);
      toast.error(`Error fetching tour schedules: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const filteredTours = tours.filter(tour => {
    const adminMatch = tour.admin && tour.admin.email === user.email;
    const companyMatch = tour.company && tour.company.email === user.email;
    return adminMatch || companyMatch;
  });

  const handleEdit = (tour) => {
    setSelectedTour(tour);
    setShowEditModal(true);
  };

  const handleClose = () => {
    setShowEditModal(false);
    setSelectedTour(null);
  };

  return (
    <div>
      <ToastContainer />
      <button className='btn btn-warning' onClick={() => navigate('/admin/dashboard/create/tour')}>Create Tour</button>
      <h1>Available Tours</h1>
      <div className="tour-list">
        {filteredTours.map(tour => {
          const matchedSchedule = schedules.find(schedule => schedule.tourid._id === tour._id);
          return (
            <Card key={tour._id} className="mb-3">
              <Card.Body>
  <Card.Title>{tour.title}</Card.Title>
  <Card.Text>{tour.description}</Card.Text>
  <Card.Text>From City: {tour.fromcity}</Card.Text>
  <Card.Text>To City: {tour.tocity}</Card.Text>
  <Card.Text>Distance: {tour.distance}</Card.Text>
  <Card.Text>Location: {tour.location}</Card.Text>
  <Card.Text>Price: {tour.price} PKR</Card.Text>
  <Card.Text>Postcode: {tour.postcode}</Card.Text>
  <Card.Text>Latitude: {tour.latitude}</Card.Text>
  <Card.Text>Longitude: {tour.longitude}</Card.Text>
  <Card.Text>Duration: {tour.duration}</Card.Text>  
  {/* Images section */}
  {tour.images && tour.images.length > 0 && (
    <div>
      <strong>Images:</strong>
      <ul>
        {tour.images.map((image, index) => (
          <li key={index}>
            <img src={image} alt={`Image ${index + 1}`} style={{ width: '100px', height: 'auto' }} />
          </li>
        ))}
      </ul>
    </div>
  )}

  {/* Tags section */}
  {tour.tags && tour.tags.length > 0 && (
    <div>
      <strong>Tags:</strong>
      <ul>
        {tour.tags.map((tag, index) => (
          <li key={index}>{tag}</li>
        ))}
      </ul>
    </div>
  )}

  {/* Categories section */}
  {tour.category && tour.category.length > 0 && (
    <div>
      <strong>Categories:</strong>
      <ul>
        {tour.category.map((cat, index) => (
          <li key={index}>{cat.title} - {cat.description}</li>
        ))}
      </ul>
    </div>
  )}

  {/* Edit Tour button */}
  <div className="d-flex gap-4">
    <Button variant="warning" onClick={() => handleEdit(tour)}>Edit Tour</Button>
  </div>
</Card.Body>

            </Card>
          );
        })}
      </div>
      {selectedTour && (
        <Edit_Tour
          show={showEditModal}
          handleClose={handleClose}
          tourData={selectedTour}
          onUpdate={fetchToursAndSchedules} // Pass the update callback
        />
      )}
    </div>
  );
};

export default Get_Tour;
