import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "reactstrap";
import axios from "axios";
import { backendurl } from "../../../config/backend";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toast CSS

const Edit_Tour = ({ show, handleClose, tourData, onUpdate }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    fromcity: "",
    tocity: "",
    distance: "",
    price: "",
    duration: "",
  });

  useEffect(() => {
    if (tourData) {
      setFormData({
        title: tourData.title || "",
        description: tourData.description || "",
        fromcity: tourData.fromcity || "",
        tocity: tourData.tocity || "",
        distance: tourData.distance || "",
        price: tourData.price || "",
        duration: tourData.duration || "",
      });
    }
  }, [tourData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token directly
      await axios.put(
        `${backendurl}/api/tour/update/${tourData._id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Tour updated successfully");
      handleClose();
      if (onUpdate) {
        onUpdate(); // Call onUpdate to refresh the data
      }
    } catch (err) {
      console.error("Error updating tour:", err);
      toast.error(`Error updating tour: ${err.message}`);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formFromCity">
              <Form.Label>From City</Form.Label>
              <Form.Control
                type="text"
                name="fromcity"
                value={formData.fromcity}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formToCity">
              <Form.Label>To City</Form.Label>
              <Form.Control
                type="text"
                name="tocity"
                value={formData.tocity}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDistance">
              <Form.Label>Distance</Form.Label>
              <Form.Control
                type="text"
                name="distance"
                value={formData.distance}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDuration">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="text"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" onClick={handleSubmit}>
            Update Tour
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Edit_Tour;
