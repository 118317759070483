import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import "../styles/login.css";
import registerImg from "../assets/images/login.png";
import userIcon from "../assets/images/user.png";
import { backendurl } from "../config/backend";

const User_Register = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
    profileImage: null, // To store selected image
  });

  const [previewImage, setPreviewImage] = useState(userIcon);
  const [showPassword, setShowPassword] = useState(false); // To toggle password visibility

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setCredentials((prev) => ({ ...prev, profileImage: file }));
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", credentials.username);
    formData.append("email", credentials.email);
    formData.append("password", credentials.password);
    formData.append("phone", credentials.phone);
    if (credentials.profileImage) {
      formData.append("profileimage", credentials.profileImage);
    }

    try {
      const res = await axios.post(`${backendurl}/api/user/create`, formData);
      toast.success(res.data); // Show success message
    } catch (error) {
      toast.error(
        error.response?.data || "Error creating account. Please try again."
      );
    }
  };

  const handleImageClick = () => {
    document.getElementById("profileImageInput").click();
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <section>
      <ToastContainer />
      <Container>
        <Row>
          <Col lg="8" className="m-auto">
            <div className="login__container d-flex justify-content-between">
              <div className="login__img">
                <img src={registerImg} alt="" />
              </div>

              <div className="login__form">
                <div className="user">
                  <img src={userIcon} alt="" />
                </div>
                <h2>Register</h2>
                <Form onSubmit={handleClick}>
                  <FormGroup>
                    <div className="text-center">
                      <img
                        src={previewImage}
                        height="100px"
                        width="100px"
                        alt="Profile Preview"
                        onClick={handleImageClick}
                        style={{ cursor: "pointer" }}
                      />
                      <input
                        type="file"
                        id="profileImageInput"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the actual file input
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <input
                      type="text"
                      placeholder="Username"
                      id="username"
                      required
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <input
                      type="email"
                      placeholder="Email"
                      id="email"
                      required
                      onChange={handleChange}
                    />
                  </FormGroup>
                  
                  <FormGroup>
                    <div className="password__input">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        id="password"
                        required
                        onChange={handleChange}
                      />
                      </div>
                  </FormGroup>

                  <FormGroup>
                    <input
                      type="number"
                      placeholder="Phone Number"
                      id="phone"
                      required
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <Button className="btn secondary__btn auth__btn" type="submit">
                    Create Account
                  </Button>
                </Form>

                <p>
                  Already have an account? <Link to="/user/login">Login</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default User_Register;
