import React from 'react'
import { useNavigate } from 'react-router-dom'
const Admin_Dashboard = () => {
  const navigate=useNavigate();
  return (
    <div className='d-flex justify-content-center '>
    <div className='d-flex flex-column gap-1 w-25 '>
      <button onClick={()=>navigate('/admin/dashboard/get/tour')}>Tour</button>
      <button>Booking</button>
      <button>Add Admin</button>
      <button>Company detail</button>
      <button>User detail</button>
      <button>reviews</button>
      <button>subscription</button>
    </div>
    </div>
  )
}

export default Admin_Dashboard
