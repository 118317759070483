// src/components/Create_Tour.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import { backendurl } from '../../../config/backend';
const Create_Tour = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    fromcity: '',
    tocity: '',
    distance: '',
    location: '',
    price: '',
    postcode: '',
    latitude: '',
    longitude: '',
    duration: '',
    fromdate: '',
    todate: '',
    availableseats: '',
    category: [{ title: '', description: '' }],
    tags: [''], // Initialize with an empty string for the first tag input
    images: [],
    admin:'66d7472a65f338606713a352',
    company:''
  });

  // const [formData, setFormData] = useState({
  //   title: 'Explore the Mountains',
  //   description: 'A thrilling adventure through the scenic mountain range.',
  //   fromcity: 'Islamabad',
  //   tocity: 'Murree',
  //   distance: '50km',
  //   location: 'Murree Hills, Pakistan',
  //   price: '5000',
  //   postcode: '47150',
  //   latitude: '33.9062',
  //   longitude: '73.3908',
  //   duration: '2 days',
  //   fromdate: '2024-09-15',
  //   todate: '2024-09-17',
  //   availableseats: '20',
  //   category: [{ title: 'Adventure', description: 'Explore thrilling mountain treks' }],
  //   tags: ['Adventure', 'Mountains', 'Nature'],
  //   images: [
  //     'https://example.com/image1.jpg',
  //     'https://example.com/image2.jpg'
  //   ],
  //   admin: '',
  //   company: '' // Example company ID
  // });
  
  // Handle general input change for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle dynamic category input change
  const handleCategoryChange = (index, e) => {
    const { name, value } = e.target;
    const newCategories = [...formData.category];
    newCategories[index][name] = value;
    setFormData({ ...formData, category: newCategories });
  };

  // Add new category input field
  const handleAddCategory = () => {
    setFormData({
      ...formData,
      category: [...formData.category, { title: '', description: '' }],
    });
  };

  // Remove category input field
  const handleRemoveCategory = (index) => {
    const newCategories = formData.category.filter((_, i) => i !== index);
    setFormData({ ...formData, category: newCategories });
  };

  // Handle dynamic tags input change
  const handleTagChange = (index, e) => {
    const newTags = [...formData.tags];
    newTags[index] = e.target.value;
    setFormData({ ...formData, tags: newTags });
  };

  // Add new tag input field
  const handleAddTag = () => {
    setFormData({
      ...formData,
      tags: [...formData.tags, ''],
    });
  };

  // Remove tag input field
  const handleRemoveTag = (index) => {
    const newTags = formData.tags.filter((_, i) => i !== index);
    setFormData({ ...formData, tags: newTags });
  };
  const handleImageChange = (index, e) => {
    const newImages = [...formData.images];
    newImages[index] = e.target.files[0]; // Store the file object directly
    setFormData({ ...formData, images: newImages });
  };

  const handleAddImage = () => {
    setFormData({ ...formData, images: [...formData.images, null] });
  };

  const handleRemoveImage = (index) => {
    const newImages = formData.images.filter((_, i) => i !== index);
    setFormData({ ...formData, images: newImages });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formDataToSend = new FormData();
    
    // Append fields to formData except category, tags, and images arrays
  for (let key in formData) {
    if (key !== 'category' && key !== 'tags' && key !== 'images') {
      formDataToSend.append(key, formData[key]);
    }
  }
    // Append category array to formData
    formData.category.forEach((cat, index) => {
      formDataToSend.append(`category[${index}][title]`, cat.title);
      formDataToSend.append(`category[${index}][description]`, cat.description);
    });
    
    // Append tags array to formData
    formData.tags.forEach((tag, index) => {
      formDataToSend.append(`tags[${index}]`, tag);
    });
    
    // Append images to formData
    formData.images.forEach((image, index) => {
      if (image) formDataToSend.append('images', image);
    });  
    
    // Console log form data
    console.log({
      title: formData.title,
      description: formData.description,
      fromcity: formData.fromcity,
      tocity: formData.tocity,
      distance: formData.distance,
      location: formData.location,
      price: formData.price,
      postcode: formData.postcode,
      latitude: formData.latitude,
      longitude: formData.longitude,
      duration: formData.duration,
      fromdate: formData.fromdate,
      todate: formData.todate,
      availableseats: formData.availableseats,
      category: formData.category,
      tags: formData.tags,
      images: formData.images,
      admin: formData.admin
    });
    
    try {
      const token = localStorage.getItem('token');
      console.log("token", token);
      const response = await axios.post(`${backendurl}/api/tour/create`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      
      toast.success('Tour created successfully!');
      // Reset form after successful submission
      setFormData({
        title: '',
        description: '',
        fromcity: '',
        tocity: '',
        distance: '',
        location: '',
        price: '',
        postcode: '',
        latitude: '',
        longitude: '',
        duration: '',
        fromdate: '',
        todate: '',
        availableseats: '',
        category: [{ title: '', description: '' }],
        tags: [''],
        images: [],
      });
    } catch (error) {
      // Display backend error message
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        if (errorMessage === 'A tour with this title already exists') {
          toast.error('A tour with this title already exists');
        } else {
          toast.error(`Error creating tour: ${errorMessage}`);
        }
      } else {
        toast.error(`Error creating tour: ${error.message}`);
      }
    }
  };
  
  return (
    <div className="container mt-4">
      <ToastContainer />
      <h1 className="text-center mb-4">Create Tour</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Tour Fields */}
        <div className="mb-3">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Description</label>
          <textarea
            className="form-control"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>From City</label>
          <input
            type="text"
            className="form-control"
            name="fromcity"
            value={formData.fromcity}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>To City</label>
          <input
            type="text"
            className="form-control"
            name="tocity"
            value={formData.tocity}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Distance</label>
          <input
            type="text"
            className="form-control"
            name="distance"
            value={formData.distance}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Location</label>
          <input
            type="text"
            className="form-control"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Price</label>
          <input
            type="number"
            className="form-control"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Postcode</label>
          <input
            type="text"
            className="form-control"
            name="postcode"
            value={formData.postcode}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Latitude</label>
          <input
            type="number"
            className="form-control"
            name="latitude"
            value={formData.latitude}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Longitude</label>
          <input
            type="number"
            className="form-control"
            name="longitude"
            value={formData.longitude}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Duration</label>
          <input
            type="text"
            className="form-control"
            name="duration"
            value={formData.duration}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>From Date</label>
          <input
            type="date"
            className="form-control"
            name="fromdate"
            value={formData.fromdate}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>To Date</label>
          <input
            type="date"
            className="form-control"
            name="todate"
            value={formData.todate}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label>Available Seats</label>
          <input
            type="number"
            className="form-control"
            name="availableseats"
            value={formData.availableseats}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Categories Section */}
        {formData.category.map((cat, index) => (
          <div key={index} className="mb-3">
            <label>Category Title</label>
            <input
              type="text"
              className="form-control"
              name="title"
              value={cat.title}
              onChange={(e) => handleCategoryChange(index, e)}
            />
            <label>Category Description</label>
            <input
              type="text"
              className="form-control"
              name="description"
              value={cat.description}
              onChange={(e) => handleCategoryChange(index, e)}
            />
            {index > 0 && (
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => handleRemoveCategory(index)}
              >
                Remove Category
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          className="btn btn-warning"
          onClick={handleAddCategory}
        >
          Add Category
        </button>

        {/* Tags Section */}
        {formData.tags.map((tag, index) => (
          <div key={index} className="mb-3">
            <label>Tag</label>
            <input
              type="text"
              className="form-control"
              value={tag}
              onChange={(e) => handleTagChange(index, e)}
            />
            {index > 0 && (
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => handleRemoveTag(index)}
              >
                Remove Tag
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          className="btn btn-warning"
          onClick={handleAddTag}
        >
          Add Tag
        </button>
{/* Images Section */}
{formData.images.map((_, index) => (
          <div key={index} className="mb-3">
            <label>Image {index + 1}</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => handleImageChange(index, e)}
            />
            {index > 0 && (
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => handleRemoveImage(index)}
              >
                Remove Image
              </button>
            )}
          </div>
        ))}
        {formData.images.length < 15 && (
          <button
            type="button"
            className="btn btn-warning mb-3"
            onClick={handleAddImage}
          >
            Add Another Image
          </button>
        )}

      <div>
        <button type="submit" className="btn btn-warning">
          Create Tour
        </button>
      </div>
      </form>
    </div>
  );
};

export default Create_Tour;
